import { Color } from '../components/ThemeProvider/ThemeStyle';

export const P_STATUS = {
  SUBMITTED: 'SUBMITTED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_FOR_SIGN: 'READY_FOR_SIGN',
  COMPLETED: 'COMPLETED',
  EXTERNAL_APP: 'IN_PROGRESS_IN_EXTERNAL_APP',
  FAILED: 'FAILED'
};

export const ROLES = {
  ES_SERVICE_PROVIDER_HEADS: 'ES_SERVICE_PROVIDER_HEADS',
  ES_SERVICE_PROVIDER_EMPLOYEES: 'ES_SERVICE_PROVIDER_EMPLOYEES',
  ES_REGION_EMPLOYEES: 'ES_REGION_EMPLOYEES',
  ES_MINISTRY_EMPLOYEES: 'ES_MINISTRY_EMPLOYEES',
  ES_GODS: 'ES_GODS',
  ES_REGION_MIO_EMPLOYEES: 'ES_REGION_MIO_EMPLOYEES',
  SUO_SCOPE_REGION: 'SUO_SCOPE_REGION',
  SELF: 'SELF',
  PRESCHOOL_TEACHER: 'PRESCHOOL_TEACHER',
  PARENT: 'PARENT',
  ATTENDANT: 'ATTENDANT',
};

export const PAGE_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  INVALID: 'INVALID'
};

export const TASK_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  INTERRUPTED: 'INTERRUPTED'
};

export const PROCESS_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FORCE_COMPLETED: 'FORCE_COMPLETED'
};

export const TASK_STATUS_COLOR = {
  IN_PROGRESS: palette => palette.info.dark,
  COMPLETED: palette => palette.success.dark,
  INTERRUPTED: palette => palette.error.dark
};

export const PROCESS_STATUS_COLOR = {
  IN_PROGRESS: palette => palette.info.dark,
  COMPLETED: palette => palette.success.dark,
  FORCE_COMPLETED: palette => palette.error.dark
};

export const TASK_TYPE = {
  ASSIGN_FUTURE_TASKS: 'ASSIGN_FUTURE_TASKS',
  QUESTIONNAIRE_TASK: 'QUESTIONNAIRE_TASK',
  FULL_BO_QUESTIONNAIRE_TASK: 'FULL_BO_QUESTIONNAIRE_TASK',
  SIGN_REQUEST_FORM: 'SIGN_REQUEST_FORM',
  SIGN_BUSINESS_OBJECT: 'SIGN_BUSINESS_OBJECT',
  SELECT_USER: 'SELECT_USER'
};

export const ROLES_NAMES = {
  [ROLES.ES_SERVICE_PROVIDER_HEADS]: {
    ru_name: 'Руководитель',
    kk_name: 'Жетекші'
  },
  [ROLES.ES_SERVICE_PROVIDER_EMPLOYEES]: {
    ru_name: 'Исполнитель',
    kk_name: 'Орындаушы'
  },
  [ROLES.ES_MINISTRY_EMPLOYEES]: {
    ru_name: 'Сотрудник МОН',
    kk_name: 'БҒМ қызметкері'
  },
  [ROLES.ES_GODS]: {
    ru_name: 'Администратор',
    kk_name: 'Администратор'
  },
  [ROLES.ES_REGION_EMPLOYEES]: {
    ru_name: 'Сотрудник управления образования',
    kk_name: 'Сотрудник управления образования'
  },
  [ROLES.ES_REGION_MIO_EMPLOYEES]: {
    ru_name: 'Сотрудник МИО',
    kk_name: 'Сотрудник МИО'
  }
};

export const TASK_STATUS_NAMES = {
  [TASK_STATUS.NOT_STARTED]: {
    code: TASK_STATUS.NOT_STARTED,
    ru_name: 'Не запущен',
    kk_name: 'Басталмады',
    disableOption: true
  },
  [TASK_STATUS.IN_PROGRESS]: {
    code: TASK_STATUS.IN_PROGRESS,
    ru_name: 'На исполнении',
    kk_name: 'Орындауда'
  },
  [TASK_STATUS.COMPLETED]: {
    code: TASK_STATUS.COMPLETED,
    ru_name: 'Завершена',
    kk_name: 'Аяқталды'
  },
  [TASK_STATUS.INTERRUPTED]: {
    code: TASK_STATUS.INTERRUPTED,
    ru_name: 'Прервана',
    kk_name: 'Үзілді',
    disableOption: true
  }
};

export const RAG_STATUS = {
  GREEN: 'GREEN',
  RED: 'RED',
  AMBER: 'AMBER'
};

export const RAG_STATUS_COLOR = {
  getColor: (value, palette) => {
    switch (value) {
      case RAG_STATUS.GREEN:
        return Color.isDark ? `${palette.success.dark}70` : `${palette.success.light}90`;
      case RAG_STATUS.RED:
        return Color.isDark ? `${palette.error.dark}70` : `${palette.error.light}90`;
      case RAG_STATUS.AMBER:
        return Color.isDark ? `${palette.warning.dark}70` : `${palette.warning.light}90`;
      default:
        return undefined;
    }
  }
};

export const BULLETIN_STATUS = {
  AVAILABLE_ALL: 'AVAILABLE_ALL',
  EXPECT_PUBLICATION: 'EXPECT_PUBLICATION'
};

export const DIRECTION_STATUS = {
  RECEIVED: 'RECEIVED',
  PROLONGED: 'PROLONGED',
  RESERVED: 'RESERVED'
};

export const STATUS_DIRECTION = {
  ARRIVED_TO_PO: 'ARRIVED_TO_PO',
  RECEIVED: 'RECEIVED',
  PROLONGED: 'PROLONGED',
  ENROLLED_TO_PO: 'ENROLLED_TO_PO'
};

export const CONTINGENT_STATUS = {
  ENROLLED: 'ENROLLED',
  EXPELLED: 'EXPELLED',
  APPLICANT: 'APPLICANT'
};

export const CONTINGENT_SCHOOL_STATUS = {
  STUDIES_SCHOOL: 'STUDIES_SCHOOL',
  LEFT_SCHOOL: 'LEFT_SCHOOL'
};

export const CONTINGENT_SECTION_STATUS = {
  VISITS_SECTION: 'VISITS_SECTION',
  LEFT_SECTION: 'LEFT_SECTION'
};

export const STATUS_DIRECTION_COLOR = {
  getColor: (value, palette) => {
    switch (value) {
      case STATUS_DIRECTION.ARRIVED_TO_PO:
        return Color.isDark ? `${palette.success.dark}70` : `${palette.success.light}90`;
      case STATUS_DIRECTION.RECEIVED:
        return Color.isDark ? `${palette.error.dark}70` : `${palette.error.light}90`;
      case STATUS_DIRECTION.PROLONGED:
        return Color.isDark ? `${palette.error.dark}70` : `${palette.error.light}90`;
      default:
        return undefined;
    }
  }
};

export const ROLES_ADMIN = {
  ES_GODS: 'ES_GODS',
  ES_ADMINS: 'ES_ADMINS'
};

export const COMPANY_NAME_ZHETISU = {
  ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА ЖЕТІСУСКОЙ ОБЛАСТИ',
  kk: 'ЖЕТІСУ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
};

export const COMPANY_NAME_ALMATY = {
  ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА АЛМАТИНСКОЙ ОБЛАСТИ',
  kk: 'АЛМАТЫ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
};

export const ROLES_CREATE_USER = {
  ES_SERVICE_PROVIDER_HEADS: 'ES_SERVICE_PROVIDER_HEADS',
  ES_SERVICE_PROVIDER_EMPLOYEES: 'ES_SERVICE_PROVIDER_EMPLOYEES',
  ES_REGION_EMPLOYEES: 'ES_REGION_EMPLOYEES',
  ES_GODS: 'ES_GODS',
  ES_REGION_MIO_EMPLOYEES: 'ES_REGION_MIO_EMPLOYEES'
};

export const months = [
  { ru: 'Январь', kk: 'Қаңтар', code: '01' },
  { ru: 'Февраль', kk: 'Ақпан', code: '02' },
  { ru: 'Март', kk: 'Наурыз', code: '03' },
  { ru: 'Апрель', kk: 'Сәуір', code: '04' },
  { ru: 'Май', kk: 'Мамыр', code: '05' },
  { ru: 'Июнь', kk: 'маусым', code: '06' },
  { ru: 'Июль', kk: 'Шілде', code: '07' },
  { ru: 'Август', kk: 'Тамыз', code: '08' },
  { ru: 'Сентябрь', kk: 'Қыркүйек', code: '09' },
  { ru: 'Октябрь', kk: 'Қазан', code: '10' },
  { ru: 'Ноябрь', kk: 'Қараша', code: '11' },
  { ru: 'Декабрь', kk: 'Желтоқсан', code: '12' }
];

export const colorsReport = [
  { color: Color.bgColorSuccess, ru: 'Присутствует', kk: 'Бар' },
  { color: Color.secondary, ru: 'Отсутствует', kk: 'Жоқ' },
  { color: Color.injury, ru: 'Есть причина', kk: 'Себебі бар' }
];
export const colorsFoodReport = [
  { color: Color.bgColorSuccess, ru: 'Поел', kk: 'Тамқатанды' },
  { color: Color.secondary, ru: 'Не поел', kk: 'Тамақтанбады' },
  { color: Color.grey, ru: 'Отказался от питание', kk: 'Тамақтанудан бас тартты' }
];
